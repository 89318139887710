
import { defineComponent, reactive } from "vue";
import { api } from "@/utils/api-config";
import { gpf } from "@/utils/global-functions";

export default defineComponent({
  setup() {
    const titleTable: Array<string> = [
      "Kode",
      "Total Skor",
      "Status",
      "Tanggal",
    ];

    // kode: "2104151TR60V",
    // totalSkor: 139,
    // status: "Gagal",
    // tanggal: "2021-04-16 00:24:35",

    type listData = {
      status: "";
      totalCAT: 0;
      average: 0;
      passingGrade: 0;
      histori: [];
    };
    const dataCG = reactive({
      slugListData: [
        {
          title: "Seleksi Kompetensi Dasar",
          slug: "listSKD",
        },
        {
          title: "Seleksi Kompetensi Teknis",
          slug: "listSKT",
        },
      ],
      listSKD: {} as listData,
      listSKT: {} as listData,
      listMSK: {} as listData,
      listSKW: {} as listData,
      btnKlaimCpns: true,
      btnKlaimPppk: true,
    });

    const getData = async () => {
      cash("#loadingCG").show();

      const hide = () => {
        cash("#loadingCG").hide();
        cash("#listDataCG").show();
      };
      const url = api.memberGaransi;
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;
      dataCG.listSKD = rs.skd;
      dataCG.listSKT = rs.skt;
      dataCG.listMSK = rs.msk;
      dataCG.listSKW = rs.skw;

      rs.skd.status === "Lulus"
        ? (dataCG.btnKlaimCpns = false)
        : (dataCG.btnKlaimCpns = true);
      rs.skt.status === "Lulus" &&
      rs.msk.status === "Lulus" &&
      rs.skw.status === "Lulus"
        ? (dataCG.btnKlaimPppk = false)
        : (dataCG.btnKlaimPppk = true);
    };
    getData();

    return {
      titleTable,
      dataCG,
    };
  },
  data() {
    return {
      exDataCG: {
        bankId: "",
        jenis: "",
        lampiran: "",
        banner: "",
        nameBanner: "Choose File",
        oversizeBanner: false,
        tempBanner: "",
      },
      listBank: [],
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataCG;
      ex[f] = val;
    },
    chooseBanner() {
      const id = "fileBannerDM";
      const btn: any = document.getElementById(id);
      btn.click();
    },
    removeBanner() {
      const ex = this.exDataCG;
      ex.nameBanner = "Choose File";
      ex.banner = "";
      cash("#showImage").attr("src", "");
    },
    handleFile(evt: any) {
      const target = evt.target;
      const files = target.files;
      const sizeFiles = target.files[0].size;
      const ex = this.exDataCG;

      if (sizeFiles > 1000000) {
        ex.oversizeBanner = true;
        this.removeBanner();
      } else {
        const nameFile = target.files[0].name;
        ex.nameBanner = nameFile;
        ex.banner = files[0];
        ex.oversizeBanner = false;

        this.renderImage(target.files[0]);
      }
    },
    renderImage(files: any) {
      // console.log('masuk render image ', files)
      const reader = new FileReader();
      reader.onload = function (e: any) {
        cash("#showImage").attr("src", e.target.result);
      };
      reader.readAsDataURL(files);
    },
    async showKlaimCG(jenis: string) {
      gpf.gLoading.show();
      const ex = this.exDataCG;
      ex.jenis = jenis;

      const hide = () => gpf.gLoading.hide();
      this.listBank = await api.getAkunBank(hide);

      gpf.showModal("#modal-klaim-garansi");
    },
    async klaimGaransiApi() {
      gpf.gLoading.show();
      const hide = () => gpf.gLoading.hide();
      const ex = this.exDataCG;
      const body = new FormData();
      body.append("bankId", ex.bankId);
      body.append("jenis", ex.jenis);
      body.append("lampiran", ex.banner);

      const configGet = await api.configGet();
      const url = api.memberGaransi;
      const hitApi = await api.hitApiPost(url, body, hide, configGet);
      await gpf.handleSds(hitApi);
    },
  },
});
